import { Button } from '@domains/shared/components/Button/Button';
import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const FeedbackContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 140px;
    margin: 16px 0 0;
    background-color: ${({ theme }): string => theme.deprecated.domains.ad.adAvmModule.feedback.backgroundColor};
`;

export const Row = styled.div`
    display: flex;
    position: relative;
`;

export const FeedbackButton = styled(Button)`
    width: 152px;
    font-size: ${SIZE.p3};
    & + & {
        margin-left: 8px;
    }

    @media (min-width: ${BREAKPOINT.sm}) {
        width: 168px;
    }
`;

export const FeedbackButtonIcon = styled(Icon)`
    margin-right: 8px;
`;

export const FeedbackTitle = styled.p`
    font-weight: ${WEIGHT.semibold};
`;

export const FeedbackThanks = styled.p`
    color: ${({ theme }): string => theme.deprecated.domains.ad.adAvmModule.feedback.color};
    font-size: ${SIZE.h5};
    font-weight: ${WEIGHT.semibold};
`;
