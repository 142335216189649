import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faThumbsDown } from '@fortawesome/free-regular-svg-icons/faThumbsDown';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons/faThumbsUp';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useCallback, useRef, useState } from 'react';

import {
    FeedbackButton,
    FeedbackButtonIcon,
    FeedbackContainer,
    FeedbackThanks,
    FeedbackTitle,
    Row,
} from './FeedbackSurvey.theme';

export const FeedbackSurvey = (): JSX.Element => {
    const [isSurveyTracked, setIsSurveyTracked] = useState(false);
    const feedbackSurveyRef = useRef<HTMLDivElement | null>(null);
    const { trackEvent } = useTracking();
    const [shouldDisplayAdAvmFeedbackSurvey, setShouldDisplayAdAvmFeedbackSurvey] = useState(true);
    const [t] = useTranslations();

    const surveyShownCallback = useCallback(
        (_?: IntersectionObserverEntry, observer?: IntersectionObserver) => {
            const { current } = feedbackSurveyRef;

            if (!isSurveyTracked) {
                trackEvent('survey_shown', {
                    survey_name: 'avm_feedback',
                });
                setIsSurveyTracked(true);
            }

            if (current && observer) {
                observer.unobserve(current);
                observer.disconnect();
            }
        },
        [trackEvent, isSurveyTracked],
    );

    useIntersection(feedbackSurveyRef, surveyShownCallback);

    const handleFeedbackButtonClick = (touchPointButton: string) => {
        return (): void => {
            trackEvent('survey_answer', {
                touch_point_page: 'ad_page',
                touch_point_button: touchPointButton,
                survey_name: 'avm_feedback',
                survey_answer: touchPointButton,
            });
            setShouldDisplayAdAvmFeedbackSurvey(false);
        };
    };

    return (
        <FeedbackContainer ref={feedbackSurveyRef} data-cy="ad.avm-module.survey">
            {shouldDisplayAdAvmFeedbackSurvey ? (
                <>
                    <Row>
                        <FeedbackTitle>{t('frontend.ad.avmmodule.feedbacktitle')}</FeedbackTitle>
                    </Row>
                    <Row>
                        <FeedbackButton
                            data-cy="ad.avm-module.survey-yes"
                            variant={ButtonVariant.Ghost}
                            onClick={handleFeedbackButtonClick('yes')}
                        >
                            <FeedbackButtonIcon icon={faThumbsUp} />
                            {t('frontend.ad.avmmodule.feedbackyes')}
                        </FeedbackButton>
                        <FeedbackButton
                            data-cy="ad.avm-module.survey-no"
                            variant={ButtonVariant.Ghost}
                            onClick={handleFeedbackButtonClick('no')}
                        >
                            <FeedbackButtonIcon icon={faThumbsDown} />
                            {t('frontend.ad.avmmodule.feedbackno')}
                        </FeedbackButton>
                    </Row>
                </>
            ) : (
                <FeedbackThanks data-cy="ad.avm-module.survey-thanks">
                    {t('frontend.ad.avmmodule.feedbackthanks')}
                </FeedbackThanks>
            )}
        </FeedbackContainer>
    );
};
