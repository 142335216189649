import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Accordion } from '@nexus/lib-react/dist/core/Accordion';
import { DialogBody } from '@nexus/lib-react/dist/core/Dialog';
import { P2 } from '@nexus/lib-react/dist/core/Typography';

export const StyledDialogBody = styled(DialogBody)`
    display: grid;
    max-height: fit-content;
    padding-right: ${theme.space['24']};
    padding-left: ${theme.space['24']};
`;

export const StyledAccordion = styled(Accordion)`
    width: 100%;
    padding: ${theme.space['0']};
`;

export const StyledP2 = styled(P2)`
    display: block;
    margin: ${theme.space['16']} ${theme.space['0']};
`;

export const ListContainer = styled.ul`
    padding-left: ${theme.space['24']};
`;
