import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { theme } from '@lib/styles/themes/nexus';
import { Popover } from '@nexus/lib-react/dist/core/Popover';
import { P3, P4 } from '@nexus/lib-react/dist/core/Typography';

export const TooltipTrigger = styled(P3)`
    text-decoration: underline;
    cursor: pointer;
`;

export const TooltipDescription = styled(P4)`
    width: 256px;
    color: ${theme.colors['text-global-inverse']};
    text-align: left;

    > span {
        display: inline-block;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        width: 400px;
    }
`;

export const LearnMoreLink = styled(P4)`
    display: block;
    color: ${theme.colors['text-global-inverse']};
    text-decoration: underline;
    cursor: pointer;
    pointer-events: auto;
`;
export const StyledPopover = styled(Popover)`
    --bgColor: ${theme.colors['background-global-inverse']};
`;
