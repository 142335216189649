import { AD_AVM_READ_MORE_MODAL_ID } from '@domains/ad/nexusComponents/AdAvmModule/components/ReadMoreModal/ReadMoreModal';
import { TooltipContent } from '@domains/ad/nexusComponents/AdAvmModule/components/Tooltip/TooltipContent';
import { TooltipContextProvider } from '@domains/ad/nexusComponents/AdAvmModule/context/TooltipContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import type { JSX } from 'react';
import { useState } from 'react';

import { StyledPopover, TooltipTrigger } from './Tooltip.theme';

export const Tooltip = (): JSX.Element => {
    const [t] = useTranslations();
    const [isOpen, setIsOpen] = useState(false);
    const { trackEvent } = useTracking();
    const { openDialog } = useDialogState(AD_AVM_READ_MORE_MODAL_ID);

    const handleOnClickOutside = (): void => {
        setIsOpen(false);
    };

    const handleOpenModal = (): void => {
        trackEvent('price_prediction_more_info', {
            touch_point_button: 'find_out_more',
        });
        openDialog();
        setIsOpen(false);
    };

    const handleToggleButtonClick = (): void => {
        setIsOpen((previousState) => !previousState);
        if (!isOpen) {
            trackEvent('avm_model_description_click', {
                touch_point_button: 'show_tooltip',
            });
        }
    };

    return (
        <TooltipContextProvider handleOpenModal={handleOpenModal}>
            <StyledPopover
                onClickOutside={handleOnClickOutside}
                placement="top-start"
                visible={isOpen}
                content={TooltipContent}
            >
                <TooltipTrigger data-cy="ad.avm-module.tooltip" onClick={handleToggleButtonClick}>
                    {t('frontend.ad.avmmodule.tooltip.trigger')}
                </TooltipTrigger>
            </StyledPopover>
        </TooltipContextProvider>
    );
};
