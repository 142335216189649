import NeighborhoodPricesIcon from '@domains/ad/nexusComponents/AdAvmModule/assets/house.svg';
import PropertyDescriptionIcon from '@domains/ad/nexusComponents/AdAvmModule/assets/key.svg';
import InfrastructureIcon from '@domains/ad/nexusComponents/AdAvmModule/assets/lighting.svg';
import LocalizationIcon from '@domains/ad/nexusComponents/AdAvmModule/assets/marker.svg';
import EnvironmentIcon from '@domains/ad/nexusComponents/AdAvmModule/assets/tree.svg';
import { replacePlaceholders } from '@domains/shared/helpers/replacePlaceholders';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { useState } from 'react';

import {
    AboveEstimateRangeLine,
    BelowEstimateRangeLine,
    CurrentPrice,
    CurrentPriceLabel,
    CurrentPriceLabelContainer,
    CurrentPricePerM,
    CurrentTotalPrice,
    DataLeft,
    DataRight,
    EstimatedRangeLine,
    HeaderWrapper,
    List,
    ListItem,
    ListItemText,
    ListTitle,
    LogoCenoskop,
    MaxEstimatedPrice,
    MinEstimatedPrice,
    PriceBig,
    PriceSmall,
    PricingSection,
    Row,
    Subtitle,
    Title,
    TitleWrapper,
} from './AdAvmModule.theme';
import { FeedbackSurvey } from './FeedbackSurvey';
import { Tooltip } from './Tooltip';

interface AdAvmModuleProps {
    price: AdvertCharacteristic;
    pricePerM?: AdvertCharacteristic;
    adArea?: AdvertCharacteristic;
    shouldExchangePricePerM: boolean;
    shouldDisplayPricePerM: boolean;
    currentPricePosition: number;
    formattedLowerPredictionPrice: string;
    formattedUpperPredictionPrice: string;
    formattedLowerPredictionPricePerM: string;
    formattedUpperPredictionPricePerM: string;
}

const LazyAdReadMoreModal = dynamic(() => import('@domains/ad/components/AdAvmModule/AdAvmReadMoreModal'), {
    ssr: false,
});

const AdAvmModule = ({
    price,
    pricePerM,
    adArea,
    shouldExchangePricePerM,
    shouldDisplayPricePerM,
    currentPricePosition,
    formattedLowerPredictionPrice,
    formattedUpperPredictionPrice,
    formattedLowerPredictionPricePerM,
    formattedUpperPredictionPricePerM,
}: AdAvmModuleProps): JSX.Element | null => {
    const [shouldDisplayAdAvmReadMoreModal, setShouldDisplayAdAvmReadMoreModal] = useState(false);
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { currency, value, localizedValue } = price;

    const closeReadMoreModal = (): void => setShouldDisplayAdAvmReadMoreModal(false);
    const openReadMoreModal = (): void => {
        setShouldDisplayAdAvmReadMoreModal(true);
        trackEvent('price_prediction_more_info', {
            touch_point_page: 'ad_page',
            touch_point_button: 'find_out_more',
        });
    };

    const getPricePerM = (): string | undefined =>
        shouldExchangePricePerM
            ? `${(Number(value) / Number(adArea?.value)).toFixed(0)} ${currency}/m²`
            : pricePerM?.localizedValue;

    return (
        <>
            <HeaderWrapper>
                <TitleWrapper>
                    <Title data-cy="ad.avm-module.title">{t('frontend.ad.avmmodule.maintitle')}</Title>
                </TitleWrapper>
                <Subtitle>
                    {replacePlaceholders(t('frontend.ad.avmmodule.mainsubtitle-b'), (text) => {
                        if (!text.includes('%upperPrice%') && !text.includes('%lowerPrice%')) {
                            return text;
                        }

                        return (
                            <b>
                                {text
                                    .replace('%upperPrice%', formattedUpperPredictionPrice)
                                    .replace('%lowerPrice%', formattedLowerPredictionPrice)}
                            </b>
                        );
                    })}
                </Subtitle>
            </HeaderWrapper>
            <Row>
                <DataLeft>
                    <LogoCenoskop data-cy="ad.avm-module.logo" />

                    <PricingSection>
                        <MinEstimatedPrice>
                            <PriceBig data-cy="ad.avm-module.min-price">{formattedLowerPredictionPrice}</PriceBig>
                            {shouldDisplayPricePerM ? (
                                <PriceSmall>{formattedLowerPredictionPricePerM}</PriceSmall>
                            ) : null}
                        </MinEstimatedPrice>
                        <MaxEstimatedPrice>
                            <PriceBig data-cy="ad.avm-module.max-price">{formattedUpperPredictionPrice}</PriceBig>
                            {shouldDisplayPricePerM ? (
                                <PriceSmall>{formattedUpperPredictionPricePerM}</PriceSmall>
                            ) : null}
                        </MaxEstimatedPrice>

                        <BelowEstimateRangeLine />
                        <EstimatedRangeLine />
                        <AboveEstimateRangeLine />

                        <CurrentPrice pricePosition={currentPricePosition}>
                            <CurrentPriceLabelContainer pricePosition={currentPricePosition}>
                                <CurrentPriceLabel>
                                    {t('frontend.ad.avmmodule.price')}:
                                    <CurrentTotalPrice>{localizedValue}</CurrentTotalPrice>
                                </CurrentPriceLabel>
                                {shouldDisplayPricePerM ? <CurrentPricePerM>{getPricePerM()}</CurrentPricePerM> : null}
                            </CurrentPriceLabelContainer>
                        </CurrentPrice>
                    </PricingSection>
                </DataLeft>
                <DataRight>
                    <>
                        <ListTitle data-cy="ad.avm-module.info-title">
                            {t('frontend.ad.avmmodule.list.title')}
                        </ListTitle>
                        <List>
                            <ListItem data-cy="ad.avm-module.info-description">
                                <PropertyDescriptionIcon></PropertyDescriptionIcon>
                                <ListItemText>{t('frontend.ad.avmmodule.list.property-description')}</ListItemText>
                            </ListItem>
                            <ListItem data-cy="ad.avm-module.info-location">
                                <LocalizationIcon></LocalizationIcon>
                                <ListItemText>{t('frontend.ad.avmmodule.list.location')}</ListItemText>
                            </ListItem>
                            <ListItem data-cy="ad.avm-module.info-environment">
                                <EnvironmentIcon></EnvironmentIcon>
                                <ListItemText>{t('frontend.ad.avmmodule.list.environment')}</ListItemText>
                            </ListItem>
                            <ListItem data-cy="ad.avm-module.info-infrastructure">
                                <InfrastructureIcon></InfrastructureIcon>
                                <ListItemText>{t('frontend.ad.avmmodule.list.infrastructure')}</ListItemText>
                            </ListItem>
                            <ListItem data-cy="ad.avm-module.info-prices">
                                <NeighborhoodPricesIcon></NeighborhoodPricesIcon>
                                <ListItemText>{t('frontend.ad.avmmodule.list.neighborhood-prices')}</ListItemText>
                            </ListItem>
                        </List>
                        <Tooltip openModal={openReadMoreModal} />
                    </>
                </DataRight>
            </Row>
            <FeedbackSurvey />
            {shouldDisplayAdAvmReadMoreModal ? (
                <LazyAdReadMoreModal isVisible={shouldDisplayAdAvmReadMoreModal} onDismiss={closeReadMoreModal} />
            ) : null}
        </>
    );
};

export default AdAvmModule;
