import { calcCurrentPricePosition } from '@domains/ad/components/AdAvmModule/helpers/calcCurrentPricePosition';
import type { AdAvmData } from '@domains/ad/types/Ad';
import { formatPrice } from '@domains/shared/helpers/price/formatPrice';
import type { Locale } from '@lib/i18n/types/locale';

interface FormattedAvmValues {
    currentPricePosition: number;
    formattedLowerPredictionPrice: string;
    formattedUpperPredictionPrice: string;
    formattedLowerPredictionPricePerM: string;
    formattedUpperPredictionPricePerM: string;
}

export const getFormattedAvmValues = (
    adAvmData: AdAvmData,
    area: string,
    lang: Locale,
    currency: string,
    value: string,
): FormattedAvmValues => {
    const { lowerPredictionPrice, lowerPredictionPricePerM, upperPredictionPrice, upperPredictionPricePerM } =
        adAvmData;

    const currentPricePosition = calcCurrentPricePosition(lowerPredictionPrice, upperPredictionPrice, Number(value));
    const formattedLowerPredictionPrice = formatPrice({
        price: lowerPredictionPrice,
        currency,
        lang,
        shouldFormatAsInteger: true,
    });
    const formattedUpperPredictionPrice = formatPrice({
        price: upperPredictionPrice,
        currency,
        lang,
        shouldFormatAsInteger: true,
    });
    const formattedLowerPredictionPricePerM = `${formatPrice({
        price: lowerPredictionPricePerM,
        currency,
        lang,
        shouldFormatAsInteger: true,
    })}/${area}`;
    const formattedUpperPredictionPricePerM = `${formatPrice({
        price: upperPredictionPricePerM,
        currency,
        lang,
        shouldFormatAsInteger: true,
    })}/${area}`;

    return {
        currentPricePosition,
        formattedLowerPredictionPrice,
        formattedUpperPredictionPrice,
        formattedLowerPredictionPricePerM,
        formattedUpperPredictionPricePerM,
    };
};
