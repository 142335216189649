import {
    ListContainer,
    StyledAccordion,
    StyledDialogBody,
    StyledP2,
} from '@domains/ad/nexusComponents/AdAvmModule/components/ReadMoreModal/ReadMoreModal.theme';
import { replacePlaceholders } from '@domains/shared/helpers/replacePlaceholders';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { Dialog, DialogHeader } from '@nexus/lib-react/dist/core/Dialog';
import { H4, P2 } from '@nexus/lib-react/dist/core/Typography';
import type { JSX } from 'react';

export const AD_AVM_READ_MORE_MODAL_ID = 'AD_AVM_READ_MORE_MODAL';

const ReadMoreModal = (): JSX.Element => {
    const [t] = useTranslations();

    const content: { id: string; isOpen: boolean; header: () => JSX.Element; content: () => JSX.Element }[] = [
        {
            id: 'ad.avm.description',
            isOpen: true,
            header: () => <P2 variant="bold">{t('frontend.ad.avm-read-more-modal.description-title')}</P2>,
            content: () => (
                <P2>
                    {replacePlaceholders(t('frontend.ad.avm-read-more-modal.description-content'), (text) => {
                        if (!text.includes('%boldText%')) {
                            return text;
                        }

                        return (
                            <b>
                                {text.replace(
                                    '%boldText%',
                                    t('frontend.ad.avm-read-more-modal.description-content-bold'),
                                )}
                            </b>
                        );
                    })}
                </P2>
            ),
        },
        {
            id: 'ad.avm.how-to-use',
            isOpen: false,
            header: () => <P2 variant="bold">{t('frontend.ad.avm-read-more-modal.how-to-use-title')}</P2>,
            content: () => (
                <P2>
                    {t('frontend.ad.avm-read-more-modal.how-to-use-content-check')}
                    <ListContainer>
                        <li>
                            <StyledP2 as="span">
                                <b>{t('frontend.ad.avm-read-more-modal.how-to-use-content-check-limits')}</b>
                                {t('frontend.ad.avm-read-more-modal.how-to-use-content-check-limits-description')}
                            </StyledP2>
                        </li>
                        <li>
                            <StyledP2 as="span">
                                <b>{t('frontend.ad.avm-read-more-modal.how-to-use-content-check-range-little')}</b>
                                {t('frontend.ad.avm-read-more-modal.how-to-use-content-check-range-little-description')}
                            </StyledP2>
                        </li>
                        <li>
                            <StyledP2 as="span">
                                <b>{t('frontend.ad.avm-read-more-modal.how-to-use-content-check-range-more')}</b>
                                {t('frontend.ad.avm-read-more-modal.how-to-use-content-check-range-more-description')}
                            </StyledP2>
                        </li>
                    </ListContainer>
                    {t('frontend.ad.avm-read-more-modal.how-to-use-content-contact-with-seller')}
                </P2>
            ),
        },
        {
            id: 'ad.avm.price-difference',
            isOpen: false,
            header: () => <P2 variant="bold">{t('frontend.ad.avm-read-more-modal.price-difference-title')}</P2>,
            content: () => (
                <>
                    <P2>{t('frontend.ad.avm-read-more-modal.price-difference-content-1')}</P2>
                    <StyledP2>{t('frontend.ad.avm-read-more-modal.price-difference-content-2')}</StyledP2>
                    <P2>{t('frontend.ad.avm-read-more-modal.price-difference-content-3')}</P2>
                </>
            ),
        },
        {
            id: 'ad.avm.missing-feature',
            isOpen: false,
            header: () => <P2 variant="bold">{t('frontend.ad.avm-read-more-modal.missing-feature-title')}</P2>,
            content: () => (
                <>
                    <P2>{t('frontend.ad.avm-read-more-modal.missing-feature-content')}</P2>
                </>
            ),
        },
    ];

    return (
        <Dialog id={AD_AVM_READ_MORE_MODAL_ID}>
            <DialogHeader>
                <H4 variant="bold">{t('frontend.ad.avm-read-more-modal.title')}</H4>
            </DialogHeader>
            <StyledDialogBody>
                <StyledAccordion content={content} openBehavior="multiple" />
            </StyledDialogBody>
        </Dialog>
    );
};

export default ReadMoreModal;
