import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { theme } from '@lib/styles/themes/nexus';
import { H4, P2, P3, P4 } from '@nexus/lib-react/dist/core/Typography';

import CenoskopLogo from './assets/cenoskopLogo.svg';

export const HeaderWrapper = styled.div`
    margin-bottom: ${theme.space['24']};
`;

export const Title = styled(H4)`
    margin: ${theme.space['0']} ${theme.space['0']} ${theme.space['24']} ${theme.space['0']};
`;

export const LogoCenoskop = styled(CenoskopLogo)`
    width: 144px;
    margin: ${theme.space['0']} ${theme.space['0']} ${theme.space['24']};
`;

export const Subtitle = styled(P3)`
    margin: ${theme.space['0']};
`;

export const PricingSection = styled.div`
    display: grid;
    position: relative;
    grid-gap: 12px 4px;
    grid-template-areas:
        '. currentPrice .'
        'belowEstimateRange estimatedRangeLine aboveEstimateRange'
        'min . max';
    grid-template-columns: auto 41% auto;
    grid-template-rows: 70px 4px 60px;
    width: 100%;
    margin: ${theme.space['0']} auto;
    padding-bottom: ${theme.space['20']};

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-columns: auto 45% auto;
    }
`;

export const EstimatedRangeLine = styled.div`
    position: relative;
    grid-area: estimatedRangeLine;
    height: 12px;
    border-radius: 4px;
    background-color: ${theme.colors['background-brand-secondary']};
`;

const OutsideEstimateRangeLine = styled.div`
    height: 12px;
    border-radius: 4px;
    background: ${theme.colors['background-brand-primary']};
`;

export const BelowEstimateRangeLine = styled(OutsideEstimateRangeLine)`
    grid-area: belowEstimateRange;
`;

export const AboveEstimateRangeLine = styled(OutsideEstimateRangeLine)`
    grid-area: aboveEstimateRange;
    transform: rotate(-180deg);
`;

export const DataTop = styled.div`
    width: 100%;
    overflow: hidden;

    @media (min-width: ${BREAKPOINT.md}) {
        margin: ${theme.space['0']};
    }
`;

export const DataBottom = styled.div`
    margin-top: ${theme.space['16']};

    @media ${theme.mediaQueries.smMin} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-top: ${theme.space['0']};
    }
`;

export const ListTitle = styled(P2)`
    margin: ${theme.space['0']} ${theme.space['0']} ${theme.space['20']};
`;

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    margin: ${theme.space['0']};
    padding: ${theme.space['0']};
    list-style: none;

    @media ${theme.mediaQueries.smMin} {
        flex-direction: row;
        justify-content: space-between;
        gap: ${theme.space['16']};
        width: 100%;
    }
`;

export const ListItem = styled.li`
    display: flex;
    align-items: center;
    margin: ${theme.space['0']} ${theme.space['0']} ${theme.space['12']};
`;

export const ListItemText = styled(P4)`
    margin-left: ${theme.space['8']};
`;

export const FlexColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
`;

const EstimatedPrice = styled.div`
    position: absolute;
    top: 16px;
    margin: ${theme.space['0']} ${theme.space['0']} ${theme.space['32']};
    text-align: center;
`;

export const MinEstimatedPrice = styled(EstimatedPrice)`
    right: 0;
    grid-area: min;
    transform: translateX(50%);
`;

export const MaxEstimatedPrice = styled(EstimatedPrice)`
    left: 0;
    grid-area: max;
    transform: translateX(-50%);
`;

export const CurrentPrice = styled.div<{ pricePosition: number }>`
    position: absolute;
    left: ${({ pricePosition }): string => `${pricePosition}%`};
    grid-area: currentPrice;
    width: 100%;
    max-width: 130px;
    transform: translateX(-50%);
    text-align: center;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: -24px;
        left: 0;
        width: 0;
        height: 0;
        margin: ${theme.space['0']} auto;
        border-width: 20px 10px 0;
        border-style: solid;
        border-color: ${theme.colors['borders-brand-primary']} transparent transparent transparent;
    }
`;

const PRICE_ALIGN: Record<string, string> = {
    '-52.5': 'start',
    '152.5': 'end',
};

const PRICE_TRANSFORM: Record<string, string> = {
    '-52.5': '30%',
    '152.5': '-30%',
};

export const CurrentPriceLabelContainer = styled.div<{ pricePosition: number }>`
    transform: translateX(${({ pricePosition }): string => PRICE_TRANSFORM[pricePosition] ?? '0%'});
    text-align: ${({ pricePosition }): string => PRICE_ALIGN[pricePosition] ?? 'center'};
`;

export const TooltipWrapper = styled.div`
    margin-top: ${theme.space['10']};
`;
