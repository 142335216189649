import {
    LearnMoreLink,
    TooltipDescription,
} from '@domains/ad/nexusComponents/AdAvmModule/components/Tooltip/Tooltip.theme';
import { TooltipContext } from '@domains/ad/nexusComponents/AdAvmModule/context/TooltipContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FC } from 'react';
import { useContext } from 'react';

export const TooltipContent: FC = () => {
    const [t] = useTranslations();
    const { handleOpenModal } = useContext(TooltipContext);

    return (
        <TooltipDescription data-cy="ad.avm-module.tooltip-description">
            <span>
                {t('frontend.ad.avmmodule.tooltip.text')}
                <LearnMoreLink as="a" variant="bold" data-cy="ad.avm-module.tooltip-more" onClick={handleOpenModal}>
                    {t('frontend.ad.avmmodule.more-b')}
                </LearnMoreLink>
            </span>
        </TooltipDescription>
    );
};
