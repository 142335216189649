/**
 *
 * @param lowerPrice - a lower price from AVM
 * @param upperPrice - a upper price from AVM
 * @param currentPrice - a current price of ad
 * @returns calculated number that determines the item's position with the current price
 */
export const calcCurrentPricePosition = (lowerPrice: number, upperPrice: number, currentPrice: number): number => {
    const rangeEstimatePrice = upperPrice - lowerPrice;
    const diffEstimatePrice = currentPrice - lowerPrice;
    const pricePosition = diffEstimatePrice && (diffEstimatePrice * 100) / rangeEstimatePrice;

    if (pricePosition < 0) {
        return -calcOutsidePricePosition(currentPrice, lowerPrice);
    }
    if (pricePosition >= 0 && pricePosition <= 10) {
        return 10;
    }
    if (pricePosition >= 90 && pricePosition <= 100) {
        return 90;
    }
    if (pricePosition > 100) {
        return 100 + calcOutsidePricePosition(currentPrice, upperPrice);
    }

    return pricePosition;
};

const calcOutsidePricePosition = (currentPrice: number, limit: number): number => {
    const diffFromLimit = Math.abs(currentPrice - limit) / limit;

    if (diffFromLimit < 0.11) {
        return 7.5;
    }
    if (diffFromLimit < 0.21) {
        return 22.5;
    }
    if (diffFromLimit < 0.31) {
        return 37.5;
    }

    return 52.5;
};
