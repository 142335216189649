import { SITE_CONFIG } from '@config/siteConfig';
import AdAvmModule from '@domains/ad/components/AdAvmModule/AdAvmModule';
import { useAdAvmQuery } from '@domains/ad/components/AdAvmModule/hooks/useAdAvmQuery';
import NexusAdAvmModule from '@domains/ad/nexusComponents/AdAvmModule/AdAvmModule';
import { getFormattedAvmValues } from '@domains/ad/nexusComponents/AdAvmModule/helpers/getFormattedAvmValues';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTracking } from '@lib/tracking/useTracking';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';
import type { JSX } from 'react';
import { useEffect, useRef } from 'react';

import { Container, NexusContainer } from './BaseAdAvmModule.theme';

export interface BaseAdAvmModuleProps {
    id: number;
    price: AdvertCharacteristic;
    pricePerM?: AdvertCharacteristic;
    adArea?: AdvertCharacteristic;
    isNexusPage?: boolean;
}

const BaseAdAvmModule = ({
    id,
    price,
    pricePerM,
    adArea,
    isNexusPage = false,
}: BaseAdAvmModuleProps): JSX.Element | null => {
    const { adAvmData, adAvmTrackingData, isFetching } = useAdAvmQuery({ id, currentPrice: price });
    const isInitialTrackEventRef = useRef(true);
    const adAvmModuleRef = useRef(null);
    const { lang } = useSiteSettings();
    const { trackEvent } = useTracking();
    const shouldDisplayPricePerM = !!pricePerM;
    const shouldExchangePricePerM = price.currency !== 'PLN';

    useEffect(() => {
        if (!isFetching && isInitialTrackEventRef.current) {
            trackEvent('avm_price_indicator', { ...adAvmTrackingData });
            isInitialTrackEventRef.current = false;
        }
    }, [isFetching, trackEvent, adAvmTrackingData]);

    useTrackImpression(adAvmModuleRef, () => {
        trackEvent('avm_price_impression');
    });

    if (!adAvmData || Object.values(adAvmData).includes(0)) {
        return null;
    }

    const { area } = SITE_CONFIG.defaultUnits;

    const {
        currentPricePosition,
        formattedLowerPredictionPrice,
        formattedUpperPredictionPrice,
        formattedLowerPredictionPricePerM,
        formattedUpperPredictionPricePerM,
    } = getFormattedAvmValues(adAvmData, area, lang, price.currency, price.value);

    const commonProps = {
        price,
        pricePerM,
        shouldExchangePricePerM,
        shouldDisplayPricePerM,
        currentPricePosition,
        formattedLowerPredictionPrice,
        formattedUpperPredictionPrice,
        formattedLowerPredictionPricePerM,
        formattedUpperPredictionPricePerM,
        adArea,
    };

    if (isNexusPage) {
        return (
            <NexusContainer ref={adAvmModuleRef} data-testid="ad-avm-module" data-cy="ad.avm-module.container">
                <NexusAdAvmModule {...commonProps} />
            </NexusContainer>
        );
    }

    return (
        <Container ref={adAvmModuleRef} data-testid="ad-avm-module" data-cy="ad.avm-module.container">
            <AdAvmModule {...commonProps} />
        </Container>
    );
};

export default BaseAdAvmModule;
