import type { AdAvmData } from '@domains/ad/types/Ad';
import { gql } from '@urql/core';

export interface AdAvmQueryError {
    error: string;
    __typename: 'AdvertAutomatedValuationModelError';
}

interface AdAvmQueryResponse {
    adAvmData: AdAvmData | AdAvmQueryError;
}

export const AD_AVM_QUERY = gql<AdAvmQueryResponse>`
    query AdAvmQuery($input: advertAutomatedValuationModelInput!) {
        adAvmData: advertAutomatedValuationModel(input: $input) {
            ... on AdvertAutomatedValuationModel {
                lowerPredictionPrice
                lowerPredictionPricePerM
                predictionPrice
                upperPredictionPrice
                upperPredictionPricePerM
                __typename
            }
            ... on AdvertAutomatedValuationModelError {
                error
            }
            ... on ErrorInternal {
                code
                message
            }
        }
    }
`;
