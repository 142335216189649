import type { AdAvmQueryError } from '@domains/ad/graphql/queries/AdAvmQuery';
import { AD_AVM_QUERY } from '@domains/ad/graphql/queries/AdAvmQuery';
import type { AdAvmData } from '@domains/ad/types/Ad';
import { useAssertGraphqlResponse } from '@lib/graphql/hooks/useAssertGraphqlResponse';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';
import { useMemo } from 'react';
import { useQuery } from 'urql';

interface Arguments {
    id: number;
    currentPrice: AdvertCharacteristic;
}

interface ReturnValue {
    isFetching: boolean;
    adAvmTrackingData: AdAvmTrackingData;
    adAvmData?: AdAvmData;
}

type PricePredictionIndicator = 'none' | 'above' | 'below' | 'in';

interface AdAvmTrackingData {
    price_prediction_indicator: PricePredictionIndicator;
    with_avm_price: number;
    estimated_price?: number;
}

const DEFAULT_AD_AVM_TRACKING_DATA: AdAvmTrackingData = {
    price_prediction_indicator: 'none',
    with_avm_price: 0,
};

const EXPECTED_ADVERT_AUTOMATED_VALUATION_MODEL_TYPENAMES = [
    'AdvertAutomatedValuationModel',
    'AdvertAutomatedValuationModelError',
] as const;

const checkIsAdAvmQueryError = (response: AdAvmData | AdAvmQueryError): response is AdAvmQueryError => {
    return !!(response && (response as AdAvmQueryError))?.error;
};

export const useAdAvmQuery = ({ id, currentPrice }: Arguments): ReturnValue => {
    const inputData = useMemo(
        () => ({ variables: { input: { advertId: id, currencyTransformation: currentPrice.currency } } }),
        [currentPrice.currency, id],
    );

    const [{ data, fetching, error, operation }] = useQuery({
        query: AD_AVM_QUERY,
        variables: inputData.variables,
    });

    const adAvmData = useAssertGraphqlResponse({
        data: data?.adAvmData,
        expectedTypenames: EXPECTED_ADVERT_AUTOMATED_VALUATION_MODEL_TYPENAMES,
        graphqlError: error,
        logErrorPrefix: '[AdAvmQuery]',
        logExtraData: inputData,
        operation,
        fetching,
    });

    if (!adAvmData || checkIsAdAvmQueryError(adAvmData)) {
        // Some error when AVM doesn't return data for Ad.
        return { adAvmTrackingData: DEFAULT_AD_AVM_TRACKING_DATA, isFetching: fetching };
    }

    const { predictionPrice, lowerPredictionPrice, upperPredictionPrice } = adAvmData;
    const currentAdPrice = Number(currentPrice.value);
    let pricePredictionIndicator: PricePredictionIndicator = 'in';

    if (currentAdPrice < lowerPredictionPrice) {
        pricePredictionIndicator = 'below';
    } else if (currentAdPrice > upperPredictionPrice) {
        pricePredictionIndicator = 'above';
    }

    return {
        isFetching: fetching,
        adAvmTrackingData: {
            with_avm_price: 1,
            estimated_price: predictionPrice,
            price_prediction_indicator: pricePredictionIndicator,
        },
        adAvmData: adAvmData,
    };
};
