import NeighborhoodPricesIcon from '@domains/ad/nexusComponents/AdAvmModule/assets/house.svg';
import PropertyDescriptionIcon from '@domains/ad/nexusComponents/AdAvmModule/assets/key.svg';
import InfrastructureIcon from '@domains/ad/nexusComponents/AdAvmModule/assets/lighting.svg';
import LocalizationIcon from '@domains/ad/nexusComponents/AdAvmModule/assets/marker.svg';
import EnvironmentIcon from '@domains/ad/nexusComponents/AdAvmModule/assets/tree.svg';
import { Tooltip } from '@domains/ad/nexusComponents/AdAvmModule/components/Tooltip/Tooltip';
import { replacePlaceholders } from '@domains/shared/helpers/replacePlaceholders';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { P2, P4 } from '@nexus/lib-react/dist/core/Typography';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';

import {
    AboveEstimateRangeLine,
    BelowEstimateRangeLine,
    CurrentPrice,
    CurrentPriceLabelContainer,
    DataBottom,
    DataTop,
    EstimatedRangeLine,
    FlexColumn,
    HeaderWrapper,
    List,
    ListItem,
    ListItemText,
    ListTitle,
    LogoCenoskop,
    MaxEstimatedPrice,
    MinEstimatedPrice,
    PricingSection,
    Subtitle,
    Title,
    TooltipWrapper,
} from './AdAvmModule.theme';

interface AdAvmModuleProps {
    price: AdvertCharacteristic;
    pricePerM?: AdvertCharacteristic;
    adArea?: AdvertCharacteristic;
    shouldExchangePricePerM: boolean;
    shouldDisplayPricePerM: boolean;
    currentPricePosition: number;
    formattedLowerPredictionPrice: string;
    formattedUpperPredictionPrice: string;
    formattedLowerPredictionPricePerM: string;
    formattedUpperPredictionPricePerM: string;
}

const LazyReadMoreModal = dynamic(
    () => import('@domains/ad/nexusComponents/AdAvmModule/components/ReadMoreModal/ReadMoreModal'),
    {
        ssr: false,
    },
);

const AdAvmModule = ({
    price,
    pricePerM,
    adArea,
    shouldExchangePricePerM,
    shouldDisplayPricePerM,
    currentPricePosition,
    formattedLowerPredictionPrice,
    formattedUpperPredictionPrice,
    formattedLowerPredictionPricePerM,
    formattedUpperPredictionPricePerM,
}: AdAvmModuleProps): JSX.Element | null => {
    const [t] = useTranslations();

    const { currency, value, localizedValue } = price;
    const getPricePerM = (): string | undefined =>
        shouldExchangePricePerM
            ? `${(Number(value) / Number(adArea?.value)).toFixed(0)} ${currency}/m²`
            : pricePerM?.localizedValue;

    return (
        <>
            <HeaderWrapper>
                <Title data-cy="ad.avm-module.title">{t('frontend.ad.avmmodule.maintitle')}</Title>
                <Subtitle>
                    {replacePlaceholders(t('frontend.ad.avmmodule.mainsubtitle-b'), (text) => {
                        if (!text.includes('%upperPrice%') && !text.includes('%lowerPrice%')) {
                            return text;
                        }

                        return (
                            <>
                                {text
                                    .replace('%upperPrice%', formattedUpperPredictionPrice)
                                    .replace('%lowerPrice%', formattedLowerPredictionPrice)}
                            </>
                        );
                    })}
                </Subtitle>
            </HeaderWrapper>
            <FlexColumn>
                <DataTop>
                    <LogoCenoskop data-cy="ad.avm-module.logo" />

                    <PricingSection>
                        <MinEstimatedPrice>
                            <P2 variant="bold" data-cy="ad.avm-module.min-price">
                                {formattedLowerPredictionPrice}
                            </P2>
                            {shouldDisplayPricePerM ? <P4>{formattedLowerPredictionPricePerM}</P4> : null}
                        </MinEstimatedPrice>
                        <MaxEstimatedPrice>
                            <P2 variant="bold" data-cy="ad.avm-module.max-price">
                                {formattedUpperPredictionPrice}
                            </P2>
                            {shouldDisplayPricePerM ? <P4>{formattedUpperPredictionPricePerM}</P4> : null}
                        </MaxEstimatedPrice>

                        <BelowEstimateRangeLine />
                        <EstimatedRangeLine />
                        <AboveEstimateRangeLine />

                        <CurrentPrice pricePosition={currentPricePosition}>
                            <CurrentPriceLabelContainer pricePosition={currentPricePosition}>
                                <P4>{t('frontend.ad.avmmodule.price')}:</P4>
                                <P2 variant="bold">{localizedValue}</P2>
                                {shouldDisplayPricePerM ? <P4>{getPricePerM()}</P4> : null}
                            </CurrentPriceLabelContainer>
                        </CurrentPrice>
                    </PricingSection>
                </DataTop>
                <DataBottom>
                    <>
                        <ListTitle variant="bold" data-cy="ad.avm-module.info-title">
                            {t('frontend.ad.avmmodule.list.title')}
                        </ListTitle>
                        <List>
                            <ListItem data-cy="ad.avm-module.info-description">
                                <PropertyDescriptionIcon></PropertyDescriptionIcon>
                                <ListItemText>{t('frontend.ad.avmmodule.list.property-description')}</ListItemText>
                            </ListItem>
                            <ListItem data-cy="ad.avm-module.info-location">
                                <LocalizationIcon></LocalizationIcon>
                                <ListItemText>{t('frontend.ad.avmmodule.list.location')}</ListItemText>
                            </ListItem>
                            <ListItem data-cy="ad.avm-module.info-infrastructure">
                                <InfrastructureIcon></InfrastructureIcon>
                                <ListItemText>{t('frontend.ad.avmmodule.list.infrastructure')}</ListItemText>
                            </ListItem>
                            <ListItem data-cy="ad.avm-module.info-environment">
                                <EnvironmentIcon></EnvironmentIcon>
                                <ListItemText>{t('frontend.ad.avmmodule.list.environment')}</ListItemText>
                            </ListItem>
                            <ListItem data-cy="ad.avm-module.info-prices">
                                <NeighborhoodPricesIcon></NeighborhoodPricesIcon>
                                <ListItemText>{t('frontend.ad.avmmodule.list.neighborhood-prices')}</ListItemText>
                            </ListItem>
                        </List>
                    </>
                </DataBottom>
                <TooltipWrapper>
                    <Tooltip />
                </TooltipWrapper>
            </FlexColumn>
            <LazyReadMoreModal />
        </>
    );
};

export default AdAvmModule;
