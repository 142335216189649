import type { JSX, PropsWithChildren } from 'react';
import { createContext } from 'react';

interface TooltipContextProps {
    handleOpenModal: () => void;
}

export const TooltipContext = createContext<TooltipContextProps>({
    handleOpenModal: () => undefined,
});

export const TooltipContextProvider = ({
    children,
    handleOpenModal,
}: PropsWithChildren<TooltipContextProps>): JSX.Element => {
    return <TooltipContext.Provider value={{ handleOpenModal }}>{children}</TooltipContext.Provider>;
};
