import CenoskopLogo from '@domains/ad/nexusComponents/AdAvmModule/assets/cenoskopLogo.svg';
import { H2 } from '@domains/shared/components/Heading/Heading.theme';
import { TextButton } from '@domains/shared/components/TextButton/TextButton';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LINE_HEIGHT, SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const HeaderWrapper = styled.div`
    margin-bottom: 16px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;

export const Title = styled(H2)`
    margin-right: 8px;
    color: ${({ theme }): string => theme.deprecated.domains.ad.adAvmModule.title.color};
    font-size: ${SIZE.h5};
    font-weight: ${WEIGHT.bold};
    line-height: ${LINE_HEIGHT.x2};
`;

export const LogoCenoskop = styled(CenoskopLogo)`
    width: 144px;
    margin: 0 0 24px;
`;

export const Subtitle = styled.p`
    max-width: 560px;
    margin: 0;
    font-size: ${SIZE.p2};

    span {
        font-weight: ${WEIGHT.bold};
    }
`;

export const PricingSection = styled.div`
    display: grid;
    position: relative;
    grid-gap: 12px 4px;
    grid-template-areas:
        '. currentPrice .'
        'belowEstimateRange estimatedRangeLine aboveEstimateRange'
        'min . max';
    grid-template-columns: auto 46% auto;
    grid-template-rows: 90px 4px 60px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;
`;

export const EstimatedRangeLine = styled.div`
    position: relative;
    grid-area: estimatedRangeLine;
    height: 12px;
    border-radius: 4px;
    background-color: ${({ theme }): string =>
        theme.deprecated.domains.ad.adAvmModule.estimatedRangeLine.backgroundColor};
`;

const OutsideEstimateRangeLine = styled.div`
    height: 12px;
    border-radius: 4px;
    background: ${({ theme }): string =>
        theme.deprecated.domains.ad.adAvmModule.outsideEstimatedRangeLine.backgroundColor};
`;

export const BelowEstimateRangeLine = styled(OutsideEstimateRangeLine)`
    grid-area: belowEstimateRange;
`;

export const AboveEstimateRangeLine = styled(OutsideEstimateRangeLine)`
    grid-area: aboveEstimateRange;
    transform: rotate(-180deg);
`;

export const DataLeft = styled.div`
    width: 100%;
    padding: 24px 16px 40px;
    overflow: hidden;
    background-color: ${({ theme }): string => theme.deprecated.domains.ad.adAvmModule.backgroundColor};

    @media (min-width: ${BREAKPOINT.md}) {
        max-width: 560px;
        margin: 0;
    }
`;

export const DataRight = styled.div`
    margin-top: 16px;

    @media (min-width: ${BREAKPOINT.sm}) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        max-width: 250px;
        margin-top: 0;
        margin-left: 16px;
    }
`;

export const ListTitle = styled.p`
    margin: 0 0 20px;
    font-weight: ${WEIGHT.semibold};
`;

export const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const ListItem = styled.li`
    display: flex;
    align-items: center;
    margin: 0 0 12px;
`;

export const ListItemText = styled.span`
    margin-left: 8px;
    font-size: ${SIZE.p4};
    font-weight: ${WEIGHT.semibold};
`;

export const PriceBig = styled.span`
    display: block;
    color: ${({ theme }): string => theme.deprecated.domains.ad.adAvmModule.price.color};
    font-size: ${SIZE.p2};
    font-weight: ${WEIGHT.bold};
`;

export const PriceSmall = styled.span`
    display: block;
    position: absolute;
    top: calc(100% + 2px);
    right: 0;
    left: 0;
    margin: 0 auto;
    color: ${({ theme }): string => theme.deprecated.domains.ad.adAvmModule.price.color};
    font-size: ${SIZE.p3};
    white-space: nowrap;
`;

export const Row = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;

    @media (min-width: ${BREAKPOINT.sm}) {
        flex-direction: row;
    }
`;

const EstimatedPrice = styled.div`
    position: absolute;
    top: 16px;
    margin: 0 0 34px;
    text-align: center;
`;

export const MinEstimatedPrice = styled(EstimatedPrice)`
    right: 0;
    grid-area: min;
    transform: translateX(50%);
`;

export const MaxEstimatedPrice = styled(EstimatedPrice)`
    left: 0;
    grid-area: max;
    transform: translateX(-50%);
`;

export const CurrentPrice = styled.div<{ pricePosition: number }>`
    position: absolute;
    top: 12px;
    left: ${({ pricePosition }): string => `${pricePosition}%`};
    grid-area: currentPrice;
    width: 100%;
    max-width: 130px;
    transform: translateX(-50%);
    text-align: center;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: -24px;
        left: 0;
        width: 0;
        height: 0;
        margin: 0 auto;
        border-width: 20px 10px 0;
        border-style: solid;
        border-color: ${({ theme }): string => theme.deprecated.domains.ad.adAvmModule.arrow.borderColor};
    }
`;

export const CurrentTotalPrice = styled.span`
    display: inline-block;
    font-weight: ${WEIGHT.bold};
    white-space: nowrap;
`;

export const CurrentPricePerM = styled.span`
    display: block;
    color: ${({ theme }): string => theme.deprecated.domains.ad.adAvmModule.currentPrice.color};
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.regular};
    white-space: nowrap;
`;

export const CurrentPriceLabel = styled.span`
    color: ${({ theme }): string => theme.deprecated.domains.ad.adAvmModule.currentPrice.color};
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.regular};
`;

const PRICE_ALIGN: Record<string, string> = {
    '-52.5': 'start',
    '152.5': 'end',
};

const PRICE_TRANSFORM: Record<string, string> = {
    '-52.5': '30%',
    '152.5': '-30%',
};

export const CurrentPriceLabelContainer = styled.div<{ pricePosition: number }>`
    transform: translateX(${({ pricePosition }): string => PRICE_TRANSFORM[pricePosition] ?? '0%'});
    text-align: ${({ pricePosition }): string => PRICE_ALIGN[pricePosition] ?? 'center'};
`;

export const LearnMore = styled(TextButton)`
    display: inline-block;
    font-size: ${SIZE.p4};
    font-weight: ${WEIGHT.semibold};
    text-align: left;
    text-decoration-line: underline;
    pointer-events: auto;
`;
