import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { WEIGHT } from '@lib/styles/partials/typography';

export const TooltipWrapper = styled.div`
    display: inline-block;
    position: relative;
    margin-top: 24px;

    span[role='tooltip'] > span:first-of-type {
        width: 320px;
        pointer-events: auto;
    }

    @media (min-width: ${BREAKPOINT.sm}) {
        margin-top: auto;
    }
`;

export const TooltipTrigger = styled.span`
    display: block;
    color: ${({ theme }): string => theme.deprecated.domains.ad.adAvmModule.tooltipTrigger.color};
    font-weight: ${WEIGHT.semibold};
    text-decoration: underline;
    cursor: pointer;
`;

export const TooltipDescription = styled.div`
    text-align: left;

    > span {
        display: inline-block;
    }
`;
