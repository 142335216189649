import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';
import { theme } from '@lib/styles/themes/nexus';

const COMMON = `
    position: relative;
    z-index: ${LAYER.x1};
    padding: ${theme.space['24']};
    background-color: ${theme.colors.$01_white};
`;

export const NexusContainer = styled.div`
    margin: ${theme.space['0']};
    ${COMMON}
`;

export const Container = styled.div`
    margin: ${theme.space['32']} ${theme.space['0']};
    ${COMMON}
`;
