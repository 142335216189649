import TooltipMessage from '@domains/shared/components/TooltipIcon/Tooltip';
import { useClickOutside } from '@domains/shared/hooks/useClickOutside/useClickOutside';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useRef, useState } from 'react';

import { LearnMore } from './AdAvmModule.theme';
import { TooltipDescription, TooltipTrigger, TooltipWrapper } from './Tooltip.theme';

interface TooltipProps {
    openModal: () => void;
}

export const Tooltip = ({ openModal }: TooltipProps): JSX.Element => {
    const [t] = useTranslations();
    const [isOpen, setIsOpen] = useState(false);
    const tooltipRef = useRef(null);
    const { trackEvent } = useTracking();

    useClickOutside(tooltipRef, () => {
        setIsOpen(false);
    });

    const handleOpenModal = (): void => {
        openModal();
        setIsOpen(false);
    };

    const handleToggleButtonClick = (): void => {
        setIsOpen((previousState) => !previousState);
        if (!isOpen) {
            trackEvent('avm_model_description_click', {
                touch_point_page: 'ad_page',
                touch_point_button: 'show_tooltip',
            });
        }
    };

    return (
        <TooltipWrapper ref={tooltipRef}>
            <TooltipTrigger data-cy="ad.avm-module.tooltip" onClick={handleToggleButtonClick}>
                {t('frontend.ad.avmmodule.tooltip.trigger')}
            </TooltipTrigger>
            {isOpen ? (
                <TooltipMessage
                    id="ad-avm-module-tooltip"
                    description={
                        <TooltipDescription data-cy="ad.avm-module.tooltip-description">
                            <span>
                                {t('frontend.ad.avmmodule.tooltip.text')}
                                <LearnMore
                                    data-cy="ad.avm-module.tooltip-more"
                                    variant="secondary"
                                    isInline
                                    onClick={handleOpenModal}
                                >
                                    {t('frontend.ad.avmmodule.more-b')}
                                </LearnMore>
                            </span>
                        </TooltipDescription>
                    }
                    width="contentWidth"
                />
            ) : null}
        </TooltipWrapper>
    );
};
